<template>
  <v-row>
    <v-col cols="12" v-if="$store.state.documents.retrieveLoader">
      <v-skeleton-template type="table-heading, list-item-two-line" />
      <v-skeleton-loader class="mt-5" transition="fade-transition" type="table" />
    </v-col>
    <v-col cols="12" v-else>
      <v-card>
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="grey-500--text subtitle-1 font-weight-medium">
            <router-link :to="{name: 'DocumentsList'}" class="subtitle-1 routerLink font-weight-medium">Facturas</router-link>
            <v-icon class="mx-1" color="grey-500">mdi-chevron-right</v-icon>
            <router-link :to="{name: 'DocumentseRetrieve', params: {id: instance.documents.id}}" class="subtitle-1 routerLink font-weight-medium">{{instance.documents.document.name}} Nº {{instance.documents.number}}</router-link>
            <v-icon class="mx-1" color="grey-500">mdi-chevron-right</v-icon>
            <span class="subtitle-1 font-weight-medium">Nota de crédito Nº {{instance.number}}</span>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-1">
              <v-btn class="ml-1" @click="dialogReminder=true" outlined color="grey-500"><v-icon small left>mdi-email</v-icon>Enviar nota de crédito</v-btn>
              <v-btn class="ml-1" @click="renderDocument('documents', 'creditnotes')" outlined color="grey-500" retain-focus-on-click><v-icon small left>mdi-file-document</v-icon>PDF</v-btn>
              <v-btn class="ml-1" v-if="instance.status === 'finalized'" @click="dialogDebitNote=true" outlined color="red darken-2" retain-focus-on-click>Nota de débito</v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0">
          <v-row class="mx-0 background">
            <v-col cols="2">
              <span class="d-block mb-1">Total</span>
              <span class="body-1 font-weight-medium mr-1">{{instance.total | currency(instance.documents.currency)}}</span>
              <span>{{instance.documents.currency}}</span>
            </v-col>
            <v-divider class="my-2" vertical />
            <v-col cols="3" class="ml-2">
              <span class="d-block mb-1 text-truncate" style="max-width: 200px;">{{instance.documents.customer.name}}</span>
              <v-btn :to="{name: 'DocumentseRetrieve', params: {id: instance.documents.id}}" text color="blue-500" height="20" retain-focus-on-click :ripple="false">{{instance.documents.document.name}} Nº {{instance.documents.number}}<v-icon right small>mdi-arrow-right</v-icon></v-btn>
            </v-col>
            <v-divider class="my-2" vertical />
            <v-col cols="2" class="ml-2">
              <span class="d-block mb-1">Fecha</span>
              <div class="body-1 font-weight-medium mt-1">{{instance.date | date}}</div>
            </v-col>
          </v-row>
          <v-divider />
          <v-list>
            <!-- debit note -->
            <template v-if="debitnotesList.length">
              <template v-for="(d, i) in debitnotesList">
                <v-list-item class="py-3" :key="d.id">
                  <v-list-item-icon><v-icon color="blue-500">mdi-text-box</v-icon></v-list-item-icon>
                  <v-list-item-title>
                    <v-btn :to="{name: 'DebitNoteRetrieve', params: {id: d.id}}" text color="blue-500" height="20" :ripple="false">{{d.document.name}} Nº {{d.number}}</v-btn>
                    <span class="ml-1">por {{d.note}}</span>
                    <span class="d-block">{{d.created | dateTime}}</span>
                  </v-list-item-title>
                </v-list-item>
                <v-divider :key="`${i}-cd-divider`"/>
              </template>
            </template>
            <!-- end debit note -->

            <!-- reminders -->
            <template v-if="messagesList.length">
              <template v-for="(d, i) in messagesList">
                <v-list-item class="py-3" :key="i">
                  <v-list-item-icon><v-icon color="blue-500">mdi-email-send</v-icon></v-list-item-icon>
                  <v-list-item-title>
                    <span class="d-block body-2 grey-500--text font-weight-medium">La nota de crédito fue enviada a {{d.recipient[0]}} <template v-if="d.recipient.length > 1">y otros {{d.recipient.length - 1}} contactos</template> por {{d.user}}</span>
                    <span class="d-block">{{d.created | dateTime}}</span>
                  </v-list-item-title>
                </v-list-item>
                <v-divider :key="`divider-${i}`" />
              </template>
            </template>
            <!-- end reminders -->
          </v-list>
          <v-row>
            <v-col class="pb-0" cols="12">
              <v-list class="transparent">
                <v-list-item>
                  <v-list-item-title class="grey-500--text subtitle-1 font-weight-medium">Información general</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="6">
              <v-list class="transparent">
                <v-list-item>
                  <v-list-item-title>Nota de la anulación</v-list-item-title>
                  <v-list-item-subtitle>{{instance.note}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Creado por</v-list-item-title>
                  <v-list-item-subtitle>{{instance.user}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Creado el</v-list-item-title>
                  <v-list-item-subtitle>{{instance.created | dateTime}}</v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="6">
              <v-list class="transparent">
                <v-list-item>
                  <v-list-item-title>Nota interna</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-subtitle v-if="instance.internal_note">{{instance.internal_note}}</v-list-item-subtitle>
                  <v-list-item-subtitle class="font-italic text--disabled" v-else>Sin nota interna</v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- tax agency -->
      <v-card class="px-0 my-5" v-if="account.country.id == 'CL' && instance.document.has_local_format">
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="grey-500--text subtitle-1 font-weight-medium">Gestión agencia fiscal</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-1">
              <v-menu left :close-on-content-click="false" transition="scale-transition" offset-y min-width="160px">
                <template v-slot:activator="{on, attrs}">
                  <v-btn v-bind="attrs" v-on="on" outlined retain-focus-on-click>
                    <v-icon left>mdi-plus-box </v-icon>Opciones<v-icon size="18" right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-btn :href="instance.local_file" target="_blank" text height="30" width="160" retain-focus-on-click :ripple="false">Descargar XML</v-btn>
              </v-menu>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="px-0">
          <v-row>
            <v-col cols="8">
              <v-list class="v-list-form transparent">
                <v-list-item>
                  <v-list-item-title>Estado</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-btn v-if="!instance.tax_agency.cl_status" @click="taxAgencyService('documents', 'creditnotes', 'tax_agency_status')" :loading="taxAgencyLoader" text color="blue-500" :ripple="false">Actualizar estado</v-btn>
                    <v-chip v-else-if="instance.tax_agency.cl_status.includes('Rechazado')" class="caption" color="defaultGrey" text-color="grey-500" small>{{instance.tax_agency.cl_status}}<v-icon class="ml-1" x-small>mdi-close</v-icon></v-chip>
                    <v-chip v-else color="lightBlue" text-color="blue-500" small>{{instance.tax_agency.cl_status}}<v-icon class="ml-1" x-small>mdi-check</v-icon></v-chip>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item v-if="instance.tax_agency.cl_status_detail">
                  <v-list-item-title>Detalle del estado</v-list-item-title>
                  <v-list-item-subtitle>{{instance.tax_agency.cl_status_detail}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    Track ID
                    <v-tooltip v-if="!instance.tax_agency.cl_id" color="white-0" right transition="scale-transition">
                      <template v-slot:activator="{on}">
                        <v-icon v-on="on" small color="red">mdi-alert</v-icon>
                      </template>
                      <span class="d-block px-3 py-2">Es requerido enviar a la agencia fiscal</span>
                    </v-tooltip>
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-left">
                    <span v-if="instance.tax_agency.cl_id">{{instance.tax_agency.cl_id}}</span>
                    <span v-else><v-btn @click="taxAgencyService('documents', 'creditnotes', 'tax_agency_send')" :loading="taxAgencyLoader" text color="blue-500" :ripple="false">Enviar a la agencia fiscal</v-btn></span>
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- end tax agency -->
    </v-col>

    <!-- dialog reminder -->
    <v-dialog v-if="!$store.state.documents.retrieveLoader" v-model="dialogReminder" width="700" persistent no-click-animation scrollable overlay-color="grey-500">
      <v-card>
        <v-toolbar flat height="55">
          <v-toolbar-title class="subtitle-1 grey-500--text font-weight-medium">Enviar nota de crédito</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-1">
              <v-btn @click="dialogReminder=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-subtitle class="py-2 px-3 background">
          <v-row align="center" no-gutters>
            <v-col cols="12">
              <v-autocomplete class="mx-0" v-model="$v.reminder.recipient.$model" :items="contactsList.concat({ name: 'Email de facturación', email: instance.documents.customer.email })" :error="$v.reminder.recipient.$error" prefix="Para" small-chips deletable-chips :hide-details="!$v.reminder.recipient.$error" required multiple chips item-text="name" item-value="email"
                hint="Selecciona al menos un destinatario" no-data-text="No existen contactos asociados al cliente" persistent-hint autofocus dense outlined color="blue-grey lighten-2">
                <template v-slot:item="data">{{data.item.name}} ({{data.item.email}})</template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row align="center" no-gutters>
            <v-col cols="12">
              <v-text-field class="mx-0" v-model="$v.reminder.subject.$model" :error="$v.reminder.subject.$error" prefix="Asunto" outlined required single-line dense maxlength="60" hint="Escribe un asunto para el mensaje" persistent-hint :hide-details="!$v.reminder.subject.$error" />
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-divider />
        <v-card-text>
          <v-row class="mx-0 py-5" align="center" justify="center">
            <v-col cols="10">
              <reminder-credit-note-email :instance="instance" :template="account.email_template" :color-background="account.color_background" @update:message="reminder.message=$event" :message.sync="reminder.message" />
            </v-col>
          </v-row>
          <v-row class="mx-0 pb-2" justify="center">
            <v-col cols="10">
              <span class="mr-2 body-2 grey-500--text">Archivos adjuntos</span>
              <v-chip class="ma-1" color="grey-500" small outlined><v-icon left small>mdi-paperclip</v-icon>{{instance.number}}.pdf</v-chip>
              <v-chip class="ma-1" v-if="instance.local_file" color="grey-500" small outlined><v-icon left small>mdi-paperclip</v-icon>{{instance.number}}.xml</v-chip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn @click="dialogReminder=false" outlined>Cancelar</v-btn>
          <v-btn @click="createReminder" :loading="$store.state.reminders.createLoader" color="blue-500">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog reminder -->

    <!-- dialog pdf -->
    <v-dialog v-model="modalRender" v-if="instance.id" width="700" persistent no-click-animation scrollable overlay-color="grey-500">
      <v-card>
        <v-toolbar flat height="55">
          <v-toolbar-title class="subtitle-1 grey-500--text font-weight-medium">PDF Nota de crédito Nº {{instance.number}}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-1">
              <v-btn @click="modalRender=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0">
          <v-row v-if="loaderRender">
            <v-col cols="12" class="py-0 ma-0">
              <v-skeleton-loader class="rounded-0" transition="fade-transition" type="table" />
            </v-col>
          </v-row>
          <v-row v-else-if="!loaderRender && !renderUrl">
            <v-col cols="12" class="py-16 text-center">
              <v-icon class="d-block" color="grey-500" size="70">mdi-image-broken-variant</v-icon>
              <span class="d-block subtitle-1">PDF no disponible</span>
            </v-col>
          </v-row>
          <div id="render"></div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn :href="renderUrl" :download="`Credit_Note_N${instance.number}`" color="blue-500">Descargar PDF</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog pdf -->

    <!-- dialog debit note -->
    <v-dialog v-model="dialogDebitNote" width="700" persistent no-click-animation scrollable overlay-color="grey-500">
      <v-card class="pa-0">
        <v-card-title class="pa-0">
          <v-toolbar flat height="55">
            <v-toolbar-title class="subtitle-1 grey-500--text font-weight-medium">Crear nota de débito<v-chip class="ml-3" color="blue-500" text-color="white" small>Beta</v-chip></v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-1">
                <v-btn class="ml-3" @click="dialogDebitNote=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <v-list class="v-list-form transparent">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="mr-3 grey-500--text font-weight-medium">Fecha</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-row class="mx-0">
                    <v-col cols="6">
                      <v-menu v-model="dateMenu" :close-on-content-click="false" offset-y transition="scale-transition" max-width="280px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="$v.debitNote.date.$model" :error="$v.debitNote.date.$error" v-bind="attrs" v-on="on" append-icon="mdi-calendar-month" outlined single-line dense readonly hide-details />
                        </template>
                        <v-date-picker v-model="$v.debitNote.date.$model" no-title @input="dateMenu=false" :min="instance.date" :max="today" type="date" locale="es-cl" width="280" color="blue">
                          <div class="mx-auto">
                            <v-btn color="blue-500" @click="() => {$v.debitNote.date.$model = today}">Hoy</v-btn>
                            <v-btn class="ml-2" outlined @click="() => {$v.debitNote.date.$model = ''}">Limpiar</v-btn>
                          </div>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="mr-3 grey-500--text font-weight-medium">Razón de la anulación</span>
                  <v-tooltip color="white-0" right max-width="280px" transition="scale-transition">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small color="grey-500">mdi-information</v-icon>
                    </template>
                    <span class="d-block px-3 py-2">Escribe una razón por la cual crearás la nota de débito. Esto será visible para tu cliente.</span>
                  </v-tooltip>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-text-field v-model="$v.debitNote.note.$model" :error="$v.debitNote.note.$error" outlined hide-details required single-line dense maxlength="60" />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="mr-3 grey-500--text font-weight-medium">Nota interna <v-chip color="grey-200" small>Opcional</v-chip></span>
                  <v-tooltip color="white-0" right max-width="280px" transition="scale-transition">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small color="grey-500">mdi-information</v-icon>
                    </template>
                    <span class="d-block px-3 py-2">Utiliza este campo para proveer información adicional sobre esta nota de débito. Esto no será visible para tu cliente.</span>
                  </v-tooltip>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-textarea v-model="$v.debitNote.internal_note.$model" :error="$v.debitNote.internal_note.$error" outlined hide-details required maxlength="512" />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn @click="dialogDebitNote=false" outlined>Cancelar</v-btn>
          <v-btn @click="createDebitNote" :loading="$store.state.documents.createLoader" color="blue-500">Crear nota de débito</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog debit note -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import ReminderCreditNoteEmail from '@/components/reminders/credit_notes/Email'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import RenderViewMixin from '@/mixins/RenderViewMixin'
import TaxAgencyViewMixin from '@/mixins/TaxAgencyViewMixin'
export default {
  inject: ['theme'],
  mixins: [
    GenericViewMixin,
    RenderViewMixin,
    TaxAgencyViewMixin
  ],
  components: {
    ReminderCreditNoteEmail,
    VSkeletonTemplate: {
      functional: true,
      render (h, { data, props, children }) {
        return h('v-skeleton-loader', {
          ...data,
          props: {
            boilerplate: true,
            elevation: 2,
            ...props
          }
        }, children)
      }
    }
  },
  data: () => ({
    dateMenu: false,
    debitNote: {
      credit_note: '',
      date: '',
      internal_note: '',
      note: ''
    },
    dialogDebitNote: false,
    dialogReminder: false,
    reminder: {
      credit_note: '',
      recipient: '',
      subject: '',
      message: ''
    }
  }),
  created () {
    this.retrieve()
  },
  computed: mapState({
    instance: state => state.documents.creditnotesDetail,
    contactsList: state => state.customers.contactsList,
    debitnotesList: state => state.documents.debitnotesList,
    messagesList: state => state.reminders.messagesList
  }),
  methods: {
    retrieve () {
      this.$store.dispatch('documents/RETRIEVE', {
        resource: 'creditnotes',
        id: this.$route.params.id,
        query: {
          expand: 'document,documents'
        }
      })
      .then(() => {
        this.$store.dispatch('documents/LIST', {
          resource: 'debitnotes',
          query: {
            expand: 'document',
            credit_note: this.instance.id
          }
        })
      })
      .then(() => {
        this.$store.dispatch('reminders/LIST', {
          resource: 'reminders/messages',
          query: {
            credit_note: this.instance.id
          }
        })
      })
      .then(() => {
        this.$store.dispatch('customers/LIST', {
          resource: 'contacts',
          query: {
            customer: this.instance.documents.customer.id
          }
        })
      })
      .then(() => {
        this.reminder.credit_note = this.instance.id
        this.reminder.subject = `${this.instance.document.name} Nº ${this.instance.number}`
        this.reminder.message = `Hola ${this.instance.documents.customer.name},\n\n`
        this.reminder.message += `adjunto ${this.instance.document.name} N° ${this.instance.number} `
        this.reminder.message += `para ${this.instance.documents.document.name} `
        this.reminder.message += `Nº ${this.instance.documents.number} del ${this.$options.filters.date(this.instance.date)} `
        this.reminder.message += `por un monto de ${this.$options.filters.currency(this.instance.total, this.instance.documents.currency)} `
        this.reminder.message += `${this.instance.documents.currency}\n\nAtte. ${this.account.name}`
      })
      .then(() => {
        this.debitNote.credit_note = this.instance.id
        this.debitNote.date = this.today
      })
    },
    createReminder () {
      this.$v.reminder.$touch()
      if (this.$v.reminder.$invalid) {
        return false
      }
      this.$store.dispatch('reminders/CREATE', {
        resource: 'reminders/messages',
        payload: this.reminder
      })
      .then(() => {
        this.dialogReminder = false
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
    },
    createDebitNote () {
      this.$v.debitNote.$touch()
      if (this.$v.debitNote.$invalid) {
        return false
      }
      this.$dialog.warning({
        title: 'Crear nota de débito',
        text: `
          <span class="d-block mb-4">Al continuar</span>
          <span class="d-block">1. La nota de crédito será anulada</span>
          <span class="d-block">2. El total de ${this.instance.documents.document.name} Nº ${this.instance.documents.number} será ajustado a ${this.$options.filters.currency(this.instance.documents.total_adjusted + this.instance.total, this.instance.documents.currency)} ${this.instance.documents.currency}</span>
          <span class="d-block my-4">¿Estás seguro de continuar?</span>
          <span class="d-block font-weight-medium">Esta acción no se puede deshacer</span>
        `,
        actions: [
          {
            color: 'blue-500',
            text: 'Crear nota de débito',
            handle: () => {
              this.createLoader = true
              this.$store.dispatch('documents/CREATE', {
                resource: 'debitnotes',
                payload: this.debitNote
                // commitInstance: false
              })
              .then((response) => {
                this.$router.push({
                  name: 'DebitNoteRetrieve',
                  params: {
                    id: response.data.id
                  }
                })
              })
              .catch((error) => {
                this.$dialog.message.error(this.parseErrors(error.response.data))
              })
            }
          }
        ]
      })
    }
  },
  validations: {
    reminder: {
      recipient: {
        required,
        maxLength: maxLength(8)
      },
      subject: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(60)
      },
      message: {
        required
      }
    },
    debitNote: {
      date: {
        required
      },
      internal_note: {
        maxLength: maxLength(1024)
      },
      note: {
        required,
        maxLength: maxLength(64)
      }
    }
  }
}
</script>