<template>
  <v-row no-gutters>
    <v-col>
      <header-admiral v-if="template === 'admiral'" :instance="instance" :message="message" :color-background="colorBackground" @update:message="updateMessage($event)" />
      <header-classic v-else-if="template === 'classic'" :instance="instance" :message="message" :color-background="colorBackground" @update:message="updateMessage($event)" />
      <header-modern v-else-if="template === 'modern'" :instance="instance" :message="message" :color-background="colorBackground" @update:message="updateMessage($event)" />
      <email-footer />
    </v-col>
  </v-row>
</template>
<script>
import EmailFooter from '@/components/reminders/EmailFooter'
import HeaderAdmiral from '@/components/reminders/credit_notes/headers/Admiral'
import HeaderClassic from '@/components/reminders/credit_notes/headers/Classic'
import HeaderModern from '@/components/reminders/credit_notes/headers/Modern'
import ReminderViewMixin from '@/mixins/ReminderViewMixin'

export default {
  props: {
    template: {
      type: String,
      required: false,
      default: () => ('modern')
    }
  },
  components: {
    EmailFooter,
    HeaderAdmiral,
    HeaderClassic,
    HeaderModern
  },
  mixins: [
    ReminderViewMixin
  ]
}
</script>